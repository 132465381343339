import React from "react";
import { NavLink } from "react-router-dom";
import { companyLinks, Pages, useCasesLinks } from "../../utils/constants";
import { ReactComponent as QuadrantLogo } from "../../assets/logos/quadrant-logo-text-light.svg";
import { ReactComponent as HipaaLogo } from "../../assets/logos/hipaa-logo.svg";
import { ReactComponent as AicpaLogo } from "../../assets/logos/aicpa-logo.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/icons/linkedin.svg";

const LinksBlock = ({ title, links }) => {
  return (
    <div className="w-fit lg:px-10 flex flex-col space-y-2.5">
      <p className="text-lg font-neue-medium">{title}</p>
      {links.map((link) => (
        <NavLink
          key={link.label}
          to={link.link}
          className="w-full text-base opacity-75 hover:opacity-100 transition-opacity"
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  );
};

const Footer = () => {
  return (
    <div className="bg-navy-dark text-white p-5 lg:p-10 pt-14 lg:pt-16">
      <div className="w-full lg:max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row justify-between pb-16 lg:pb-20 gap-10">
          <div>
            <NavLink to={Pages.HOME} aria-label="Home">
              <QuadrantLogo className="mb-5" />
            </NavLink>
            <p className="text-base mb-10 max-w-xs">
              Harness your data to make better <br />
              decisions for your patients, faster.
            </p>
            <p className="text-base mb-10 max-w-xs">
              Support Text Line: (216) 242-4761
            </p>
            <div className="flex items-center space-x-10">
              <HipaaLogo width="92" height="33" />
              <AicpaLogo width="65" height="65" />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-7">
            <LinksBlock title="Platform" links={useCasesLinks} />
            <div className="w-fit flex flex-col space-y-2.5">
              <LinksBlock title="Company" links={companyLinks} />
              <button
                type="button"
                onClick={() => {
                  window.displayPreferenceModal();
                  return false;
                }}
                id="termly-consent-preferences"
                className="lg:px-10 w-full text-base text-left opacity-75 hover:opacity-100 transition-opacity"
              >
                Consent Preferences
              </button>
              <a
                href="https://app.termly.io/notify/b841e4c0-28e7-4b64-8ee1-515ab52070c5"
                className="lg:px-10 w-full text-base opacity-75 hover:opacity-100 transition-opacity"
              >
                Do Not Sell or Share My Personal information
              </a>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-10 text-sm">
            <p className="opacity-75">
              © Quadrant Technologies, Inc. {new Date().getFullYear()}. All
              right reserved.
            </p>
            <NavLink
              to={Pages.PRIVACY}
              className="opacity-75 hover:opacity-100 transition-opacity"
            >
              Privacy notice
            </NavLink>
            <NavLink
              to={Pages.TERMS}
              className="opacity-75 hover:opacity-100 transition-opacity"
            >
              Terms of use
            </NavLink>
            <NavLink
              to={Pages.DISCLAIMER}
              className="opacity-75 hover:opacity-100 transition-opacity"
            >
              Disclaimer
            </NavLink>
          </div>
          <div className="flex items-center space-x-4">
            <a
              href="https://www.facebook.com/Quadrant-Health-105517108942463"
              rel="noreferrer"
              target="_blank"
              title="facebook"
              className="hover:scale-110 transition-scale duration-150"
            >
              <FacebookIcon fill="white" width="28px" height="28px" />
            </a>
            <a
              href="https://twitter.com/QuadrantHealth"
              rel="noreferrer"
              target="_blank"
              title="twitter"
              className="hover:scale-110 transition-scale duration-150"
            >
              <TwitterIcon fill="white" width="28px" height="27px" />
            </a>
            <a
              href="https://www.linkedin.com/company/quadrant-health/about/"
              rel="noreferrer"
              target="_blank"
              title="linkedin"
              className="hover:scale-110 transition-scale duration-150"
            >
              <LinkedinIcon fill="white" width="28px" height="27px" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
