import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { companyLinks, Pages, useCasesLinks } from "../../utils/constants";
import DropdownLinks from "../Basic/DropdownLinks";
import Button from "../Basic/Button";
import { ReactComponent as LogoLight } from "../../assets/logos/quadrant-logo-text-light.svg";
import { ReactComponent as LogoDark } from "../../assets/logos/quadrant-logo-text-dark.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

const navbarVariants = {
  white: "bg-white text-navy-dark",
  navy: "bg-navy-dark text-white",
  beige: "bg-beige text-navy-dark",
};

const Navbar = ({ variant = "white" }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <nav
      className={`fixed top-0 h-14 lg:h-26 z-20 w-full px-5 lg:px-10 flex items-center ${navbarVariants[variant]}`}
    >
      <div className="flex items-center justify-between space-x-20 w-full max-w-360 mx-auto font-medium text-lg whitespace-nowrap">
        <div className="flex items-center">
          <NavLink
            aria-label="Home"
            to={Pages.HOME}
            className="z-50"
            onClick={() => setOpenMobileMenu(false)}
          >
            {variant === "navy" ? (
              <LogoLight
                width={isDesktop ? "156" : "130"}
                height={isDesktop ? "48" : "40"}
              />
            ) : (
              <LogoDark
                width={isDesktop ? "156" : "130"}
                height={isDesktop ? "48" : "40"}
              />
            )}
          </NavLink>
          {isDesktop && (
            <div className="flex items-center space-x-12 ml-20">
              <DropdownLinks
                label="Platform"
                options={useCasesLinks}
                arrowColor={variant === "navy" ? "#FFFFFF" : "#001E2B"}
              />
              <DropdownLinks
                label="Company"
                options={companyLinks}
                arrowColor={variant === "navy" ? "#FFFFFF" : "#001E2B"}
              />
            </div>
          )}
        </div>
        {isDesktop ? (
          <div className="flex items-center space-x-8">
            <a href="https://app.quadrant.health/login">
              <Button variant="gray-outline">Sign in</Button>
            </a>
            <NavLink to={Pages.CONTACT}>
              <Button className="w-full">Request demo</Button>
            </NavLink>
          </div>
        ) : (
          <button
            onClick={() => setOpenMobileMenu(!openMobileMenu)}
            className="z-50"
            aria-label={openMobileMenu ? "Close menu" : "Open menu"}
          >
            {openMobileMenu ? (
              <CloseIcon stroke="#101010" width="24" height="24" />
            ) : (
              <MenuIcon width="24" height="24" />
            )}
          </button>
        )}
      </div>

      <div className="fixed lg:hidden top-0 left-0 h-screen overflow-hidden pt-14 z-10">
        <div
          className={`h-full bg-white transition-all duration-300 overflow-x-hidden flex flex-col justify-between pb-8 ${
            openMobileMenu ? "w-screen" : "w-0"
          }`}
        >
          <div className="flex flex-col pt-5 px-5 pb-6">
            <DropdownLinks
              label="Platform"
              options={useCasesLinks}
              arrowColor={variant === "navy" ? "#FFFFFF" : "#001E2B"}
              onClickLink={() => setOpenMobileMenu(false)}
            />
            <DropdownLinks
              label="Company"
              options={companyLinks}
              arrowColor={variant === "navy" ? "#FFFFFF" : "#001E2B"}
              onClickLink={() => setOpenMobileMenu(false)}
            />
          </div>
          <div className="bg-zinc-50 h-full flex flex-col space-y-2.5 pt-8 px-5">
            <a
              href="https://app.quadrant.health/login"
              className="h-12 lg:w-fit flex items-center justify-center"
            >
              Sign in
            </a>

            <NavLink to={Pages.CONTACT}>
              <Button className="w-full">Request demo</Button>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
