import React from "react";

const buttonVariants = {
  white: "bg-white text-blue-primary hover:text-navy-dark",
  "white-secondary": "bg-transparent text-white border border-white",
  blue: "bg-blue-primary text-white hover:bg-blue-dark",
  "blue-secondary":
    "bg-transparent text-blue-primary border border-blue-primary hover:bg-blue-primary hover:text-white",
  green:
    "bg-green-primary text-navy-dark font-neue-medium border border-green-primary",
  "green-secondary":
    "bg-transparent text-green-primary border border-green-primary font-neue-medium",
  navy: "bg-navy text-white font-neue-medium border border-navy",
  "navy-dark": "bg-navy-dark text-white font-neue-medium",
  "gray-outline": "text-gray-dark border border-gray-light font-neue-medium",
};

const Button = ({ variant = "green", children, className, ...props }) => {
  return (
    <button
      className={`rounded-full py-3.5 px-6 text-base font-medium flex items-center justify-center 
        transition-all duration-300 leading-tight
        ${buttonVariants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
