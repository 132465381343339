export const Pages = {
  HOME: "/",
  CARE_MANAGEMENT: "/care-management",
  SCRIBE: "/scribe",
  ADMINISTRATIVE: "/administrative",
  CASE_STUDIES: "/case-studies",
  BETHANY_MEDICAL: "/case-studies/bethany-medical-clinic",
  CONTACT: "/contact",
  PRIVACY: "/privacy-notice",
  TERMS: "/terms-of-use",
  DISCLAIMER: "/disclaimer",
  THANK_YOU: "/thank-you",
  TRY_FRONT_DESK: "/tryfrontdesk",
};

export const useCasesLinks = [
  { label: "Care Management", link: Pages.CARE_MANAGEMENT },
  { label: "Scribe", link: Pages.SCRIBE },
  { label: "Administrative", link: Pages.ADMINISTRATIVE },
];

export const companyLinks = [
  { label: "Blog", link: "https://blog.quadrant.health" },
  { label: "Request demo", link: Pages.CONTACT },
  { label: "Case studies", link: Pages.CASE_STUDIES },
];

// 1. CEO: The primary decision-makers for the company.
// 2. Medical Director: A physician who provides guidance and leadership on the use of medicine within the company.
// 3. Physician: Medical professionals who may use or influence the use of your products or services.
// 4. Engineer: Professionals who work on the technical aspects of your product or infrastructure.
// 5. Practice Manager: Individuals who manage the operations of a medical practice.
// 6. Data Analyst: Professionals handling the company’s health data analysis.
// 7. Sales Representative: Team members who sell the startup’s products or services.
// 8. Marketing Manager: Individuals managing the company’s marketing efforts.
// 9. Other: *** Free text ****

export const roles = [
  { label: "CEO", value: "CEO" },
  { label: "Medical Director", value: "Medical Director" },
  { label: "Physician", value: "Physician" },
  { label: "Engineer", value: "Engineer" },
  { label: "Practice Manager", value: "Practice Manager" },
  { label: "Data Analyst", value: "Data Analyst" },
  { label: "Sales Representative", value: "Sales Representative" },
  { label: "Marketing Manager", value: "Marketing Manager" },
  { label: "Other", value: "Other" },
];

export const requestDemoLink = "https://w93jwghrztm.typeform.com/to/P03yE6eF";

// privacy notice, terms, disclaimer styles
export const headerStyle = "scroll-mt-32 font-semibold";
export const blockStile = "mb-8 space-y-4";
export const listStyle = "list-disc ml-8";
export const linkStyle = "inline-block text-blue-primary hover:underline";
export const tdStyle = "p-2";
