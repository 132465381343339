import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";

const DropdownLinks = ({ label, arrowColor, options, onClickLink }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        if (isDesktop) setOpenDropdown(true);
      }}
      onMouseLeave={() => {
        if (isDesktop) setOpenDropdown(false);
      }}
      onClick={() => {
        if (!isDesktop) setOpenDropdown(!openDropdown);
      }}
    >
      <button className="flex items-center space-x-4 py-2 justify-between w-full lg:w-fit h-12 lg:h-fit">
        <p>{label}</p>
        <ArrowIcon
          stroke={arrowColor}
          className={`transition-all duration-300
            ${openDropdown ? "-rotate-180" : ""}`}
          width="24"
          height="7"
        />
      </button>

      <div
        className={`lg:absolute w-full lg:w-52 top-full left-1/2 lg:-translate-x-1/2 rounded-lg
          px-2 flex flex-col overflow-hidden transition-all duration-300
          ${
            openDropdown
              ? `py-2 lg:border border-surface-400 bg-white 
                ${options.length === 2 ? "h-28" : "h-40"}`
              : "h-0 py-0 border-0 border-transparent"
          }`}
      >
        {options.map((option) => (
          <NavLink
            key={option.label}
            to={option.link}
            onClick={
              onClickLink ? () => onClickLink() : () => setOpenDropdown(false)
            }
            className={({
              isActive,
            }) => `w-full text-base text-navy-dark p-3 h-12 rounded-lg hover:bg-green-light transition-colors
              ${isActive ? "font-neue-bold" : "font-neue-normal"}`}
          >
            {option.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default DropdownLinks;
